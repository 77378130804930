
import { loadSectionTranslations } from '../../i18n-setup'
import { setCountdown } from '../../libs/utils/setCountdown'

export default {
  name: 'sgh-hero-banner',
  props: {
    mobileFixedLayout: {
      type: Boolean,
      default: true,
    },
    textPosition: {
      type: String,
      default: 'top-left',
    },
    textOutside: {
      type: Boolean,
      default: true,
    },
    targetBanner: {
      type: Array,
      default: () => ['first', 'second'],
    },
    textColor: {
      type: String,
      default: 'cms-custom-text-color-black',
    },
    textBgColor: {
      type: String,
      default: 'bg-white',
    },
    brandName: {
      type: String,
      default: '',
    },
    isBgVideo: {
      type: Boolean,
      default: false,
    },
    bannerSize: {
      type: String,
      default: 'm',
    },
    numCta: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      isLoaded: false,
      isVideoPlaying: false,
      isVideoMuted: true,
      rect: null,
    }
  },
  computed: {
    isMoreThanTreeButtons() {
      return this.numCta % 3 == 0
    },
    isTextOutside() {
      return this.textOutside ? 'text-outside' : 'text-inside'
    },
    targetBannerClass() {
      return this.targetBanner.length > 1 ? 'double-link' : 'single-link'
    },
    isAudioOn() {
      return !this.$refs.image?.children[0]?.muted
    },
    isMobile() {
      return this.$mq == 'tablet' || this.$mq == 'tabletLandscape'
    },
    buttonWidth() {
      const textModule = this.$refs?.textModule;
      if (!textModule || !textModule.children) return []

      const heroButtons = [...textModule.children].find(
        element => element.className === 'hero-banner__buttons'
      )

      return heroButtons ? heroButtons.children : []
    },
  },
  watch: {
    isLoaded(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          if (this.mobileFixedLayout && this.isMobile) {
            const buttons = this.$el.querySelectorAll('.hero-banner__buttons a')
            buttons.forEach(button => {
              button.classList.remove('dark')
            })
          }
          const setButtonAsync = async () => {
            await this.setButtonWidth()
          }
          setButtonAsync()
        })
      }
    },
  },
  mounted() {
    loadSectionTranslations('cms').then(() => {
      this.$nextTick(function() {
        this.setCountDownMethod()
      })
    })
    if (this.isBgVideo) {
      this.setVideoSrc()
      this.videoIsLoaded()
    } else {
      this.imgIsLoaded(this.$refs.image?.children[0]?.children)
    }
    window.addEventListener('resize', this.setVideoSrc)
  },
  methods: {
    setCountDownMethod() {
      let content = [...this.$refs.textModule?.children]
      let endsInTranslation = this.$t('promobar_ends_in')
      let now = new Date()
      now = Date.parse(now).toString()
      content?.map(slot => {
        const startDate = slot?.dataset?.start

        const distance = slot?.dataset?.end - now
        const hiddenExpire = distance <= 0 && slot?.dataset?.hideCountdownExpired === 'true'

        if (slot?.dataset?.countdown === 'true' && !hiddenExpire && startDate <= now) {
          setInterval(
            function() {
              this.innerHTML = `${endsInTranslation} ${setCountdown(`${this.dataset?.end}`)}`
            }.bind(slot),
            100
          )
          slot.innerHTML = `${endsInTranslation} ${setCountdown(`${this.dataset?.end}`)}`
          if (slot?.dataset?.highlighted == 'true') {
            slot.classList?.add('highlighted')
          }
        }
      })
    },

    async imgIsLoaded(img) {
      if (img) {
        var imgElement = [...img].find(x => x.tagName === 'IMG')
      }
      if (!img || imgElement?.loading === 'lazy') {
        this.isLoaded = true
        return
      }
      return new Promise((resolve, reject) => {
        imgElement.addEventListener('load', () => {
          this.isLoaded = true
          resolve()
        })
        imgElement.addEventListener('error', () => {
          reject()
        })
      })
    },
    async videoIsLoaded() {
      const video = this.$refs.image?.children[0]
      const posterImage = new Image()
      posterImage.src = video.poster
      return new Promise((resolve, reject) => {
        video.addEventListener('loadeddata', () => {
          this.isLoaded = true
          resolve()
        })
        video.addEventListener('error', () => {
          reject()
        })
        posterImage.addEventListener('load', () => {
          this.isLoaded = true
          resolve()
        })
        posterImage.addEventListener('error', () => {
          reject()
        })
      })
    },

    setVideoSrc() {
      if (this.isBgVideo) {
        const video = this.$refs.image?.children[0]
        const dataMobileSrc = this.$refs.image?.children[0]?.dataset.mobileSrc
        const dataDesktopSrc = this.$refs.image?.children[0]?.dataset.desktopSrc
        const dataMobilePoster = this.$refs.image?.children[0]?.dataset.mobilePoster
        const dataDesktopPoster = this.$refs.image?.children[0]?.dataset.desktopPoster
        if (dataDesktopPoster && dataMobilePoster) {
          video.poster = this.isMobile ? dataMobilePoster : dataDesktopPoster
        }
        if (dataDesktopSrc && dataMobileSrc) {
          video.children[0].src = this.isMobile ? dataMobileSrc : dataDesktopSrc
        }
        this.isVideoAutoplay()
      }
    },
    addAnimation(body) {
      let dynamicStyles = null
      if (!dynamicStyles) {
        dynamicStyles = document.createElement('style')
        dynamicStyles.type = 'text/css'
        document.head.appendChild(dynamicStyles)
      }
      dynamicStyles.sheet.insertRule(body, dynamicStyles.length)
    },
    playVideo() {
      const video = this.$refs.image?.children[0]
      if (video) {
        if (video.paused) {
          video.play()
          this.isVideoPlaying = true
        } else {
          video.pause()
          this.isVideoPlaying = false
        }
      }
    },
    isVideoAutoplay() {
      const video = this.$refs.image?.children[0]
      if (video.autoplay) {
        return (this.isVideoPlaying = true)
      }
    },

    audioControllVideo() {
      const audio = this.$refs.image?.children[0]
      if (audio) {
        if (audio.muted) {
          audio.muted = false
          this.isVideoMuted = false
        } else {
          audio.muted = true
          this.isVideoMuted = true
        }
      }
    },
    async setButtonWidth() {
      let maxWidthButton = 0
      const arrayBytton = [...this.buttonWidth]
      arrayBytton.forEach(e => {
        if (e.offsetWidth > maxWidthButton) {
          maxWidthButton = e.offsetWidth
        }
      })
      arrayBytton.forEach(e => {
        e.style.width = `${maxWidthButton + 1}px`
      })
    },
  },
}
