import Vue from 'vue'

/* All components to be declared */
/* Declare in this way components that will be put in chunks and loaded not immediately */
Vue.component('sgh-cms-faces', () =>
  import(
    /* webpackChunkName: "faces" */
    './components/faces/cms-faces.vue'
  )
)

Vue.component('sgh-get-support-contact-us', () =>
  import(
    /* webpackChunkName: "get-support" */
    './components/get-support/get-support-contact-us.vue'
  )
)

Vue.component('sgh-get-support-giftcard', () =>
  import(
    /* webpackChunkName: "get-support" */
    './components/get-support/get-support-giftcard.vue'
  )
)

Vue.component('sgh-modal', () =>
  import(
    /* webpackChunkName: "utilities" */
    './components/modal/sgh-modal.vue'
  )
)

Vue.component('sgh-cms-landscape-banner-module', () =>
  import(
    /* webpackChunkName: "cms-modules" */
    './components/cms-modules/landscape-banner-module/landscape-banner.vue'
  )
)

Vue.component('sgh-cms-strip-banner', () =>
  import(
    /* webpackChunkName: "cms-modules" */
    './components/cms-modules/strip-banner-module/strip-banner.vue'
  )
)

Vue.component('sgh-shortcode', () =>
  import(
    /* webpackChunkName: "get-support" */
    './components/shortcode/sgh-shortcode.vue'
  )
)

Vue.component('sgh-size-advisor-entry-point', () =>
  import(
    /* webpackChunkName: "size-advisor" */
    './components/size-advisor/sgh-size-advisor-entry-point.vue'
  )
)

Vue.component('sgh-size-advisor', () =>
  import(
    /* webpackChunkName: "size-advisor" */
    './components/size-advisor/size-advisor.vue'
  )
)

Vue.component('sgh-size-guide', () =>
  import(
    /* webpackChunkName: "size-guide" */
    './components/size-guide/size-guide.vue'
  )
)

Vue.component('sgh-sizeguide-badges', () =>
  import(
    /* webpackChunkName: "size-guide" */
    './components/size-guide/sizeguide-badges.vue'
  )
)

Vue.component('sgh-sizeguide-container', () =>
  import(
    /* webpackChunkName: "size-guide" */
    './components/size-guide/sizeguide-container.vue'
  )
)

Vue.component('sgh-size-selector-list', () =>
  import(
    /* webpackChunkName: "size-list" */
    './components/size-selector/size-selector-list.vue'
  )
)

Vue.component('cms-c4-slider', () =>
  import(
    /* webpackChunkName: "cms-slider" */
    './components/slider/cms-c4-slider.vue'
  )
)

Vue.component('cms-video-slider', () =>
  import(
    /* webpackChunkName: "cms-slider" */
    './components/slider/cms-video-slider.vue'
  )
)

Vue.component('sgh-thank-promo', () =>
  import(
    /* webpackChunkName: "cms-modules" */
    './components/thank-page/thank-promo.vue'
  )
)

Vue.component('sgh-category-module-item', () =>
  import(
    /* webpackChunkName: "banner" */
    './components/cms-modules/category-module/category-module-item.vue'
  )
)

Vue.component('sgh-full-width', () =>
  import(
    /* webpackChunkName: "banner" */
    './components/full-width-banner/full-width.vue'
  )
)

Vue.component('sgh-cms-grid-plp-tiles', () =>
  import(
    /* webpackChunkName: "cms-modules" */
    './components/tile/cms-grid-plp-tiles.vue'
  )
)

Vue.component('sgh-loyalty-banner', () =>
  import(
    /* webpackChunkName: "loyalty" */
    './components/loyalty/loyalty-banner.vue'
  )
)

Vue.component('sgh-loyalty-modal', () =>
  import(
    /* webpackChunkName: "loyalty" */
    './components/loyalty/loyalty-modal.vue'
  )
)
Vue.component('sgh-loyalty-tile', () =>
  import(
    /* webpackChunkName: "loyalty" */
    './components/loyalty/loyalty-tile.vue'
  )
)

Vue.component('sgh-loyalty-confirmation-banner', () =>
  import(
    /* webpackChunkName: "loyalty" */
    './components/loyalty/loyalty-confirmation-banner.vue'
  )
)

Vue.component('sgh-sl-location-search', () =>
  import(
    /* webpackChunkName: "store-locator" */
    './components/location-search/sl-location-search.vue'
  )
)

Vue.component('sgh-tooltip-box', () =>
  import(
    /* webpackChunkName: "utilities" */
    './components/tooltip/tooltip-box.vue'
  )
)

/* Declare in this way component that needs to appear immediately (will be put in main.js) */
import heroBanner from './components/hero-banner/hero-banner.vue'
Vue.component('sgh-hero-banner', heroBanner)

import sghSwiperTiles from './components/tile/cms-swiper-tiles.vue'
Vue.component('sgh-swiper-tiles', sghSwiperTiles)

import sghCmsProductTile from './components/tile/cms-tile.vue'
Vue.component('sgh-cms-product-tile', sghCmsProductTile)

import cmsWallSlider from './components/slider/cms-wall-slider.vue'
Vue.component('cms-wall-slider', cmsWallSlider)

import sghBrandWall from './components/ui-components/sgh-brand-wall.vue'
Vue.component('sgh-brand-wall', sghBrandWall)

import sghServiceCarousel from './components/service-module/sgh-service-carousel.vue'
Vue.component('sgh-service-carousel', sghServiceCarousel)

import sghServiceCarouselItem from './components/service-module/sgh-service-carousel-item.vue'
Vue.component('sgh-service-carousel-item', sghServiceCarouselItem)

import sghServiceModule from './components/service-module/sgh-service-module.vue'
Vue.component('sgh-service-module', sghServiceModule)

import sghServiceItem from './components/service-module/sgh-service-item.vue'
Vue.component('sgh-service-item', sghServiceItem)

import sghCategoryModule from './components/cms-modules/category-module/category-module.vue'
Vue.component('sgh-category-module', sghCategoryModule)

import sghCategoryModuleItem from './components/cms-modules/category-module/category-module-item.vue'
Vue.component('sgh-category-module-item', sghCategoryModuleItem)

import sunLoveProtection from './components/sun-love-protection/sun-love-protection.vue'
Vue.component('sgh-sun-love-protection', sunLoveProtection)

import sghPaymentInfo from './components/payment-info/sgh-payment-info.vue'
Vue.component('sgh-payment-info', sghPaymentInfo)