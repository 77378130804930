
export default {
  name: 'sgh-service-item',
  props: {
    titleService: {
      type: String,
      default: '',
    },
    fullBlock: {
      type: Boolean,
      default: false,
    },
    linkService: {
      type: String,
      default: '',
    },
  },

  computed: {
    isCta() {
      return !!this.$slots['cta'] && !this.fullBlock
    },
  },
}
