import {
  UPDATE_CURRENT_PRODUCT_ID,
  UPDATE_LOADED_PRODUCTS,
  UPDATE_RECENTLY_SEARCHED_CAROUSEL,
  UPDATE_LOAD_CMS_PLACEMENTS,
  UPDATE_LOAD_FI,
  RESET_FI,
  UPDATE_COLOR_VARIANTS,
  UPDATE_PRODUCT_SIZES,
  UPDATE_EDD,
  UPDATE_ENGRAVING_INFO,
  UPDATE_ENGRAVING_PANEL_VISIBILITY,
  SET_ADD_TO_BAG_ACTIVE,
  SET_ADD_TO_BAG_LOADING,
  SET_ADD_TO_BAG_TEXT,
  SET_VIDEO_PLAYER
} from '../mutations/pdp'
import wcs from '../../libs/wcs/index'
import { Commit } from 'vuex';
import { ProductType, MainProductType, SizeType, } from '../types/pdp.types'
import axiosInstance from '../../../axios-mocks/mock-api/axiosMockApi'
import { isLocalHost } from '../../libs/utils/url'
import axios from 'axios'
import { getCurrentCountry } from '../../libs/utils/currentCountry'
import { includePlacement } from '../../libs/wcs/cms'
import { parseValue, safeJSONParse } from '../../libs/utils/string'
import { getFindStoresListNormalized } from '../../libs/normalizer'
import { loadProductSizes } from '../../libs/wcs/pdp'
import { algoliaRecentlySearched } from '../../libs/utils/pdp'
import isEmpty from 'lodash/isEmpty'

export const ACTION_LOAD_CMS_PLACEMENTS = 'ACTION_LOAD_CMS_PLACEMENTS'
export const ACTION_LOAD_PDP = 'ACTION_LOAD_PDP'
export const ACTION_LOAD_PDP_COLOR_VARIANTS = 'ACTION_LOAD_PDP_COLOR_VARIANTS'
export const ACTION_LOAD_RECENTLY_SEARCHED = 'ACTION_LOAD_RECENTLY_SEARCHED'
export const ACTION_LOAD_FIS = 'ACTION_LOAD_FIS'
export const ACTION_RESET_FIS = 'ACTION_RESET_FIS'
export const ACTION_UPDATE_CURRENT_PRODUCT_ID = 'ACTION_UPDATE_CURRENT_PRODUCT_ID'
export const ACTION_UPDATE_LOADED_PRODUCTS = 'ACTION_UPDATE_LOADED_PRODUCTS'
export const ACTION_SAVE_RECENTLY_SEARCHED_SESSION = 'ACTION_SAVE_RECENTLY_SEARCHED_SESSION'
export const ACTION_LOAD_PRODUCT_SIZES = 'ACTION_LOAD_PRODUCT_SIZES'
export const ACTION_FETCH_EDD = 'ACTION_FETCH_EDD'
export const ACTION_UPDATE_ENGRAVING_INFO = 'ACTION_UPDATE_ENGRAVING_INFO'
export const ACTION_UPDATE_ENGRAVING_PANEL_VISIBILITY = 'ACTION_UPDATE_ENGRAVING_PANEL_VISIBILITY'
export const ACTION_GET_ENGRAVING_DATA = 'ACTION_GET_ENGRAVING_DATA'
export const ACTION_TOGGLE_POPUP = 'ACTION_TOGGLE_POPUP'
export const ACTION_SET_ADD_TO_BAG_STATE = 'ACTION_SET_ADD_TO_BAG_STATE'
export const SET_ADD_TO_BAG_LOADING_STATE = 'SET_ADD_TO_BAG_LOADING_STATE'
export const SET_ADD_TO_BAG_TEXT_ACTION = 'SET_ADD_TO_BAG_TEXT_ACTION'
export const ACTION_SET_VIDEO_PLAYER = 'ACTION_SET_VIDEO_PLAYER'
const { storeId, langId } = wcs.getStoreConfig()

export const actions = {
  [ACTION_UPDATE_CURRENT_PRODUCT_ID]: function ({ commit }: { commit: Commit }, productId: string) {
    commit(UPDATE_CURRENT_PRODUCT_ID, productId)
  },

  [ACTION_UPDATE_LOADED_PRODUCTS]: async function ({ commit }: { commit: Commit }, productId: string) {
    //switch response mock for local env
    let response = {} as MainProductType

    if (isLocalHost()) {
      response = (await axiosInstance.get(`mock-api/typescript/product/${productId}`)).data.result;
    } else {
      response = (await axios.get(`/wcs/resources/store/${storeId}/products/${productId}?langId=${langId}`)).data
    }
    commit(UPDATE_LOADED_PRODUCTS, response)
    //after UI is updated we can fetch inventory and commit to update inventory availability:
    //todo is not blocking lcp but still may delay what happens inside updateLoadedProducts.then, check if it makes sense to put inventory check inside .then
    try {
      const inventoryResponse = await axios.get(`${response.links.inventory}`);
      response.InventoryAvailability = inventoryResponse.data.InventoryAvailability;
    } catch (error) {
      console.error('Error fetching inventory availability:', error);
      response.InventoryAvailability = [];
    }
    commit(UPDATE_LOADED_PRODUCTS, response)
  },

  [ACTION_LOAD_PDP_COLOR_VARIANTS]: async function ({ commit }: { commit: Commit }, mainProduct: MainProductType) {
    //switch response mock for local env
    let response = {} as any
    if (isLocalHost()) {
      response = (await axiosInstance.get("mock-api/typescript/product/color-variants")).data.result;
    } else {
      try {
        response = (await axios.get(`${mainProduct.links.variants}&langId=${langId}`)).data
      } catch (error: any) {
        throw new Error('Error fetching product variants', error)
      }
    }

    //if response is empty populate with main product to show one thumb
    if ( isEmpty(response.variants) ) {
      response.variants = [
        {
          "productId": mainProduct.productId,
          "upc": mainProduct.upc,
          "model": mainProduct.model,
          "url": mainProduct.links.url,
          "variantImageUrl": mainProduct.images[0].url,
        },
        
      ]
    }
    
    commit(UPDATE_COLOR_VARIANTS, response)
  },
  [ACTION_SET_ADD_TO_BAG_STATE]: function ({ commit }: { commit: Commit }, payload: boolean) {
    commit(SET_ADD_TO_BAG_ACTIVE, payload)
  },

  [ACTION_LOAD_RECENTLY_SEARCHED]: async function ({ commit }: { commit: Commit }, currentProduct: MainProductType) {
    let response = [] as any
    let recentlyViewed = JSON.parse(sessionStorage.getItem('PDP_RECENTLY_VIEWED') || '[]')

    // fetch products from upc and update the state
    let recentlySearchedCarouselProducts = [] as any
    recentlySearchedCarouselProducts.hits = [] as ProductType[]


    // Filter out the current product from the recently viewed
    const recentlyViewedFiltered = recentlyViewed.filter((upc: string) => upc !== currentProduct.upc) // list of UPC to fetch wihout the current product

    //avoid showing more than one gift card or egift card:
    let hasGiftCard = false;
    let hasVirtualGiftCard = false;

    if (recentlyViewedFiltered && recentlyViewedFiltered.length > 0) {
      await algoliaRecentlySearched(recentlyViewedFiltered).then((products) => {
        response = products // response from algolia - array of products 
      })
    }

    response.forEach((product: any) => {
      if (product.attributes.PRODUCT_TYPE === "GiftCards") {
        if (product.attributes.MODEL_NAME === 'Gift Card' && !hasGiftCard) {
          recentlySearchedCarouselProducts.hits.push(product);
          hasGiftCard = true;
        } else if (product.attributes.MODEL_NAME === 'Gift Card Digital' && !hasVirtualGiftCard) {
          recentlySearchedCarouselProducts.hits.push(product);
          hasVirtualGiftCard = true;
        }
      } else {
        recentlySearchedCarouselProducts.hits.push(product);
      }
    });

    commit(UPDATE_RECENTLY_SEARCHED_CAROUSEL, recentlySearchedCarouselProducts)
  },


  [ACTION_SAVE_RECENTLY_SEARCHED_SESSION]: async function ({ commit }: { commit: Commit }, currentUpc: string ) {
    let recentlyViewed = JSON.parse(sessionStorage.getItem('PDP_RECENTLY_VIEWED') || '[]')

    // update the recently viewed products upc in the session storage
    if (!recentlyViewed.includes(currentUpc)) {
      recentlyViewed.push(currentUpc)
    } else {
      recentlyViewed = recentlyViewed.filter((upc: string) => upc !== currentUpc)
      recentlyViewed.push(currentUpc)
    }

    // if the recently viewed products exceed 10, remove the first one
    if (recentlyViewed.length > 10) {
      recentlyViewed.shift()
    }
    sessionStorage.setItem('PDP_RECENTLY_VIEWED', JSON.stringify(recentlyViewed))
  },
  [ACTION_LOAD_CMS_PLACEMENTS]: async function ({ commit }: { commit: Commit }, param: any) {
    const name = `pdpPlacements_${param.productId}${window.algoliaConfig.isEarlyAccessParam ? 'EA' : ''}`
    let data =
      safeJSONParse(sessionStorage.getItem(`${name}_` + getCurrentCountry())) ||
      (await includePlacement(param, name)) //todo check if is needed await
    if (data) {
      let container = document.createElement('div')
      container.innerHTML = data.content
      const childrens = container.querySelectorAll('div [placement-id]')
      let placements: any[] = []
      childrens.forEach(children => {
        const child: any = children.children[0]
        if (child) {
          let name = child.localName
          let templates: any = {}
          let props: any = {}
          for (let template of child.children) {
            templates[template.attributes[0].localName.replace('#', '')] =
              template.innerHTML || template.content.textContent
          }
          for (let attribute of child.attributes) {
            props[attribute.localName.replace(':', '')] = parseValue(attribute.value)
          }
          placements.push({ name, templates, props })
        }
      })
      commit(UPDATE_LOAD_CMS_PLACEMENTS, placements)
    }
  },

  [ACTION_LOAD_FIS]: async function ({ commit }: { commit: Commit }, position: any = {}) {
    const { storeId, langId } = wcs.getStoreConfig()
    try {
      const formData: any = new FormData()
      formData.append('zipcode', position.location)
      formData.append('zip', position.zip_code)
      formData.append('latitude', position.lat)
      formData.append('longitude', position.lng)
      formData.append('city', position.city)
      formData.append('state', position.initial)
      formData.append('street1', position.location)
      formData.append('partnumbers[]', position.upc)
      formData.append('storeId', storeId)
      formData.append('langId', langId)
      formData.append('parent', '.find-in-store-modal')
      formData.append('searchTarget', 'FIS')
      formData.append('partnumbers', position.upc)

      // Create a timeout promise
      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => reject(new Error("Request timed out")), 5000);
      });
      // Request promise
      const requestPromise = isLocalHost() ? axiosInstance.get("mock-api/typescript/pdp/store-locator") : axios.post('/AjaxSGHFindPhysicalStoresWithInventoryForProduct', formData);
      // Race between our request and the timeout
      const response = await Promise.race([requestPromise, timeoutPromise]);
      const data = getFindStoresListNormalized(response);
      commit(UPDATE_LOAD_FI, { ...data, location: position.location || '' })
    } catch (error: any) {
      // Here you can handle the timeout specifically if needed
      if (error.message === "Request timed out") {
        commit(RESET_FI)
      }
      throw new Error("Failed load find in store", { cause: error });
    }
  },

  [ACTION_RESET_FIS]: function ({ commit }: { commit: Commit }) {
    commit(RESET_FI)
  },

  [ACTION_LOAD_PRODUCT_SIZES]: async function ({ commit }: { commit: Commit }, productId: string) {
    let productSizes: SizeType[] = await loadProductSizes(productId)
    if (productSizes) commit(UPDATE_PRODUCT_SIZES, productSizes)
  },

  [ACTION_FETCH_EDD]: async function ({ commit }: { commit: Commit }, productId: string) {
    try {
      const response = await axios.get(`/wcs/resources/store/${storeId}/products/${productId}/edd`)
      const edd = response.data.edd
      commit(UPDATE_EDD, edd)
    } catch (error: any) {
      throw new Error('Failed to fetch EDD', error)
    }
  },
  [ACTION_UPDATE_ENGRAVING_INFO]: async function ({ commit }: { commit: Commit }, payload: any) {
    commit(UPDATE_ENGRAVING_INFO, payload)
  },

  [ACTION_UPDATE_ENGRAVING_PANEL_VISIBILITY]: async function ({ commit }: { commit: Commit }, payload: boolean) {
    commit(UPDATE_ENGRAVING_PANEL_VISIBILITY, payload)
  },

  [ACTION_GET_ENGRAVING_DATA]: async function({ commit }: { commit: Commit }, payload: any) {
    //TODO: use action for getting engraving data
  },
  [SET_ADD_TO_BAG_LOADING_STATE]: function ({ commit }: { commit: Commit }, payload: boolean) {
    commit(SET_ADD_TO_BAG_LOADING, payload)
  },
  [SET_ADD_TO_BAG_TEXT_ACTION]: function ({ commit }: { commit: Commit }, payload: string) {
    commit(SET_ADD_TO_BAG_TEXT, payload)
  },
  [ACTION_SET_VIDEO_PLAYER]: function ({ commit }: { commit: Commit }, payload: any) {
    commit(SET_VIDEO_PLAYER, payload)
  }
}
