import { actions } from '../actions/pdp'
import { getters } from '../getters/pdp'
import { mutations } from '../mutations/pdp'
import { PdpStateType, MainProductType, ColorVariantsType, SizeType } from '../types/pdp.types'

export const pdp = {
  namespaced: true,
  state: {
    mainProduct: {} as MainProductType,
    mainProductSizes: [] as SizeType[],
    currentProductId: '',
    edd:'',
    loadedProducts: [] as MainProductType[],
    colorVariants: {} as ColorVariantsType[],
    recentlySearchedCarousel: [],
    cmsPlacements: [],
    findInStore: {
      stores: [],
      location: '',
      latitude: 1,
      longitude: 1,
      radius: '',
      showTab: 'map',
      filters: [],
      filtersCheckedList: {
        storeType: [],
        storeBrands: [],
        bookingAvailable: [],
        rxAvailable: [],
        stellaAvailable: [],
      },
      filtersAppliedList: {
        storeType: [],
        storeBrands: [],
        bookingAvailable: [],
        rxAvailable: [],
        stellaAvailable: [],
      }, //used to reset active filters when cta not pressed
      storesFiltered: [],
      storesVisible: [],
    },
    engravingInfo: {
      engravingData: {
        data: {
          engravingJson: {},
          engravingOrderItemId: '',
          orderItemId: '',
          prices: {},
          tempEngravingId: '',
        },
        formattedPrice: '',
        price: 0,
      },
      hasEngraving: false,
      itemPartNumber: '',
    },
    engravingPanelIsOpen: false,
    isAddToBagActive: true,
    isAddToBagLoading: false,
    addToBagButtonAction: '',
    videoPlayer: [
      {
        index: 0,
        minutes: 0,
        isPlaying: false,
      }
    ]
  } as PdpStateType,
  actions,
  getters,
  mutations,
}
