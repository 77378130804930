
export default {
  name: 'sgh-service-module',
  props: {
    fullBlock: {
      type: Boolean,
      default: false,
    },
  },
}
